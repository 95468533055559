import { Directive, ElementRef, HostListener, Input, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Directive({
  selector: "[appOnFocus]"
})
export class OnFocusDirective {
  @Input() focusClasses: Array<string>;

  constructor(private ele: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

  @HostListener("focus") onElementFocus() {
    if (isPlatformBrowser(this.platformId)) {
      this.focusClasses.map(className => {
        this.ele.nativeElement.parentElement.classList.add(className);
      });
    }
  }

  @HostListener("blur") onElementBlur() {
    if (isPlatformBrowser(this.platformId)) {
      this.focusClasses.map(className => {
        this.ele.nativeElement.parentElement.classList.remove(this.focusClasses);
      });
    }
  }
}
