import { Injectable } from "@angular/core";
import { DrawerService } from "src/app/shared/services/drawer.service";
import { error_msg } from "src/app/shared/constants/consts";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class CommonLangService {
  pageCommonText = {
    NO_DATA_FOUND: {
      EN: "No Data Found",
      AR: "لاتوجد بيانات"
    },
    Validation_Email_Valid: {
      EN: "Please enter a valid email address",
      AR: "الرجاء ادخال بريد الكتروني صالح"
    },
    FIELD_REQUIRED: { EN: "This field is required", AR: "هذه الخانة مطلوبه" },
    MIN_REQUIRED_2: {
      EN: "This field should have at least 2 characters",
      AR: "يجب أن يحتوي هذا الحقل على 2 أحرف كحد أدنى"
    },
    MIN_LENGTH_3: {
      EN: "This field should have at least 3 characters",
      AR: "الرجاء ادخال على الأقل 3 أحرف"
    },
    MAX_LENGTH_20: {
      EN: "This field should not exceed 20 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 20 حرفاُ"
    },
    MAX_LENGTH_30: {
      EN: "This field should not exceed 30 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 30 حرفاُ"
    },
    MAX_LENGTH_40: {
      EN: "This field should not exceed 40 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 40 حرفاُ"
    },
    MIN_REQUIRED_8: {
      EN: "This field should have at least 8 characters",
      AR: "يجب أن يحتوي هذا الحقل على 8 أحرف كحد أدنى"
    },
    MAX_LENGTH_12: {
      EN: "This field should not exceed 12 characters",
      AR: "لا يمكن أن يتجاوز هذا الحقل 12 حرفًا"
    },
    MAX_LENGTH_50: {
      EN: "This field should not exceed 50 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 50 حرفاُ"
    },

    ONLY_NUMERIC: {
      EN: "Please enter only numbers",
      AR: "الرجاء ادخال ارقام فقط"
    },
    ONLY_ALPHA: {
      EN: "Please enter only alphabetic characters",
      AR: "الرجاء ادخال احرف ابجدية فقط"
    },
    CELL_NUMBER_REQUIRED: {
      EN: "Please enter a valid phone number",
      AR: "الرجاء ادخال رقم هاتف صالح"
    },
    CELL_NUMBER_MIN_LENGTH_6: {
      EN: "Phone number should have minimum 6 numbers",
      AR: "يجب أن يكون رقم الجوال بحد أدنى 6 أرقام"
    },
    CELL_NUMBER_MAX_LENGTHTH_12: {
      EN: "Phone number must contain 8 to 12 digits",
      AR: "يجب ان يحتوي رقم الهاتف على ما بين 8 و 12 رقماً"
    },
    CELL_NUMBER_PATTERN: {
      EN: "Phone number must include numbers only",
      AR: "يجب ان يحتوي رقم الهاتف على ارقام فقط"
    },
    OTP_REQUIRED: { EN: "Please enter OTP", AR: "يرجى إدخال OTP" },

    MIN_REQUIRED_3: {
      EN: "This field should have at least 3 characters",
      AR: "الرجاء ادخال على الأقل 3 أحرف"
    },
    MAX_LENGTH_200: {
      EN: "This field should not exceed 200 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 200 حرفاً أو رقماً"
    },
    MAX_LENGTH_250: {
      EN: "This field should not exceed 250 characters",
      AR: "لا يمكن أن يتجاوز هذا الحقل 250 حرفًا"
    },
    MAX_LENGTH_350: {
      EN: "This field should not exceed 350 characters",
      AR: "لا يمكن أن يتجاوز هذا الحقل 350 حرفًا"
    },
    MAX_REQUIRED_25: {
      EN: "This field should not exceed 25 characters",
      AR: "الرجاء ادخال ما لا يزيد عن 25 حرفاً"
    },
    MAX_REQUIRED_30: {
      EN: "This field should not exceed 30 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 30 رقمًا"
    },
    MAX_REQUIRED_50: {
      EN: "This field should not exceed 50 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 50 رقمًا"
    },
    MAX_REQUIRED_100: {
      EN: "This field should not exceed 100 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 100 رقمًا"
    },
    MAX_REQUIRED_150: {
      EN: "This field should not exceed 150 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 150 رقمًا"
    },
    MAX_REQUIRED_500: {
      EN: "This field should not exceed 500 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 500 رقم"
    },
    MAX_REQUIRED_255: {
      EN: "This field should not exceed 255 number",
      AR: "لا يمكن أن يتجاوز هذا الحقل 255 رقم"
    },
    MIN_PRICE_0: {
      EN: "The price should be greater than 0",
      AR: "يجب ان يكون السعر أكثر من 0"
    },
    MIN_PRICE_FEE_0: {
      EN: "Fees must be at least 1",
      AR: "يجب ان تكون الرسوم 1 كحد ادنى"
    },
    MIN_SMATR_CARD_9: {
      EN: "This field should have 9 digits",
      AR: "الرجاء ادخال 9 أرقام"
    },
    MAX_SMATR_CARD_9: {
      EN: "This field should have 9 digits",
      AR: "الرجاء ادخال 9 أرقام"
    },
    MAX_PRICE_10000: {
      EN: "This field should not exceed 10,000",
      AR: "يجب ان تكون الرسوم اقل من 10,000"
    },
    MIN_VALUE_1: {
      EN: "This field should be at least 1",
      AR: "يجب أن يكون هذا الحقل 1 عدد لا يقل عن"
    },
    MAX_VALUE_5: {
      EN: "This field should not exceed 5",
      AR: "لا يمكن أن يتجاوز هذا الحقل 5 أرقا"
    },
    MAX_VALUE_10: {
      EN: "This field should not exceed 10",
      AR: "لا يمكن أن يتجاوز هذا الحقل 10 أرقام"
    },
    MAX_VALUE_50: {
      EN: "This field should not exceed 50",
      AR: "لا يمكن أن يتجاوز هذا الحقل 50 أرقام"
    },
    MAX_VALUE_100: {
      EN: "This field should not exceed 100",
      AR: "لا يمكن أن يتجاوز هذا الحقل 100 رقم"
    },
    MAX_VALUE_200: {
      EN: "This field should not exceed 200",
      AR: "لا يمكن أن يتجاوز هذا الحقل 200 رقم"
    },
    WEBSITE_URL_VALID: {
      EN: "The website link format is invalid",
      AR: "رابط الموقع غير صالح"
    },
    ATLEAST_ONE_REQUIRED: {
      EN: "At lest one field is required",
      AR: "مطلوب حقل واحد على الأقل"
    },
    FILE_SIZE_LIMIT_350: {
      EN: "File size should be greater than 350*350",
      AR: "يجب أن يكون حجم الملف أكبر من 350 * 350"
    },
    DATE_REQUIRED: {
      EN: "Please enter your resignation date",
      AR: "الرجاء ادخال تاريخ الاستقالة"
    },
    PASSWORD_MIN_MAX: {
      EN: "Password must contain 8 to 12 alphanumeric characters",
      AR: "يجب ان تحتوي كلمة السر على ما بين 8 و 12 حرفاً او رقماً"
    },

    Confirm_Password_placeholder: { EN: "Confirm Password", AR: "أكد كلمة السر" },
    New_Password_placeholder: { EN: "Enter New Password", AR: "ادخل كلمة سر جديدة" },
    Confirm_New_Password: { EN: "Confirm New Password", AR: "الرجاء تأكيد كلمة السر" },
    Please_Confirm_New_Password: { EN: "Please confirm password", AR: "الرجاء تأكيد كلمة السر" },
    Password_placeholder: { EN: "Enter Password", AR: "ادخل كلمة السر" },

    Dr_Text: { EN: "Dr. ", AR: "د. " },
    Next_Button: { EN: "Next", AR: "التالي" },
    Previous_Button: { EN: "Previous", AR: "السابق" },
    Min_Text: { EN: "Mins", AR: "دقيقة" },
    Hour: { EN: "Hour", AR: "ساعة" },
    Hours: { EN: "Hours", AR: "ساعات" },
    No_Reason: { EN: "No reason provided", AR: "السبب غير متوفر" },
    //Appointments: { EN: "Appointments", AR: "مواعيد" },
    Appointments: { EN: "Appointments", AR: "المواعيد" },
    Upcoming: { EN: "Upcoming", AR: "القادمة" },
    Past: { EN: "Past", AR: "الماضية" },
    Patient: { EN: "Patient", AR: "المريض" },
    Swapped: { EN: "Swapped", AR: "التبديلات" },
    Requestor: { EN: "Requestor", AR: "الطالب" },
    Acceptor: { EN: "Acceptor", AR: "القابل" },
    Hospitals: { EN: "Hospitals", AR: "المستشفيات" },
    Reject: { EN: "Reject", AR: "رفض" },
    Accept: { EN: "Accept", AR: "قبول" },
    Notifications: { EN: "Notifications", AR: "إخطارات" },
    View_All: { EN: "View All", AR: "عرض الكل" },
    Reason_for_visit: { EN: "View Reason", AR: "عرض السبب" },
    Cancellation_reason: { EN: "Cancellation Reason", AR: "سبب الإلغاء" },
    Password_And_Confirm_Password_Not_Match: {
      EN: "Password and confirm password do not match",
      AR: "كلمتي السر لا تتطابق"
    },

    Unsaved_Changed: {
      EN:
        "You have not saved your changes. Click Cancel to go back and save your changes or Continue to navigate away and lose your changes",
      AR:
        "لم يتم حفظ التغييرات. اظغط على إلغاء للعودة إلى الصفحة وحفظ التغييرات أو المتابعة اذا لا تريد حفظ التغييرات."
    },
    Ok: { EN: "OK", AR: "حسناً" },
    Cancel: { EN: "Cancel", AR: "إلغاء" },
    Continue: { EN: "Continue", AR: "المتابعة" },
    Reset: { EN: "Reset", AR: "إعادة تعيين" },
    Apply: { EN: "Apply", AR: "بحث" },
    Confirm: { EN: "Confirm", AR: "تأكيد" },
    Cancel_text: {
      EN: "Are you sure you want to cancel",
      AR: "هل أنت متأكد أنك تريد إلغاء"
    },
    Cancel_text_request: {
      EN: "Are you sure you want to cancel this request?",
      AR: "هل تريد إلغاء طلب الإنضمام؟"
    },
    Reject_text: {
      EN: "Are you sure you want to reject?",
      AR: "هل أنت متأكد من أنك تريد الرفض؟"
    },
    Reject_Appointment_text: {
      EN: "Are you sure you want to reject this appointment?",
      AR: "هل تريد رفض الطلب لهذا الموعد؟"
    },
    Request_Reject_text: {
      EN: "Are you sure you want to reject this request?",
      AR: "هل تريد رفض طلب الإنضمام؟"
    },
    Yes: { EN: "Yes", AR: "نعم" },
    No: { EN: "No", AR: "لا" },
    First_Name: { EN: "First Name", AR: "الاسم الاول" },
    Last_Name: { EN: "Last Name", AR: "اسم العائلة" },
    Gender: { EN: "Gender", AR: "الجنس" },
    Dob: { EN: "Date of Birth", AR: "تاريخ الميلاد" },
    Mobile: { EN: "Phone Number", AR: "رقم الهاتف" },
    Email: { EN: "Email", AR: "البريد الإلكتروني" },
    Email_Address: { EN: "Email ID", AR: "عنوان البريد الالكتروني" },
    Select_Country: { EN: "Select Country", AR: "اختر الدولة" },
    Smartcard_Number: { EN: "Smartcard number", AR: "الرقم الشخصي" },
    Smartcard_placeholder: {
      EN: "Please enter Smartcard number",
      AR: "الرجاء ادخال الرقم الشخصي"
    },
    City: { EN: "City", AR: "ادخل المدينة" },
    Load_more: { EN: "Load more", AR: "عرض المزيد" },
    Requested: { EN: "Requested", AR: "تم الطلب" },
    Confirmed: { EN: "Confirmed", AR: "مؤكد" },
    Date_Time: { EN: "Date & Time", AR: "التاريخ والوقت" },
    Fee: { EN: "Fee", AR: "الرسوم" },
    Consultaion_Fee: { EN: "Consultation Fee", AR: "رسوم الاستشارة" },
    Status: { EN: "Status", AR: "الحالة" },
    Action: { EN: "Action", AR: "إجراء" },
    Completed: { EN: "Completed", AR: "تم" },
    Complete: { EN: "Complete", AR: "اكتمال" },
    Cancelled: { EN: "Cancelled", AR: "ملغي" },
    Rejected: { EN: "Rejected", AR: "تم الرفض" },
    No_Response: { EN: "No Response", AR: "لم يرد الطبيب" },
    Height: { EN: "Height", AR: "الطول" },
    Weight: { EN: "Weight", AR: "الوزن" },
    medical_heading: { EN: "Medical Records", AR: "مستندات طبية" },
    Marital_status: { EN: "Marital Status", AR: "الحالة الزوجية" },
    Smartcard_no: { EN: "Smartcard No", AR: "البطاقة الذكية" },
    Save: { EN: "Save", AR: "حفظ" },
    Add: { EN: "Add", AR: "إضافة" },
    Submit: { EN: "Submit", AR: "حفظ" },
    Update: { EN: "Update", AR: "تحديث" },
    Join: { EN: "Join", AR: "الإنضمام" },
    Edit: { EN: "Edit", AR: "تعديل" },
    Delete: { EN: "Delete", AR: "حذف" },
    Add_Fee: { EN: "Add Fee", AR: "إضافة الرسوم" },
    Update_Fee: { EN: "Update Fee", AR: "تحديث الرسوم" },
    Delete_Text: {
      EN: "Are you sure you want to delete this entry?",
      AR: "هل تريد حذف هذا الإدخال؟"
    },
    Phone_Number: { EN: "Phone Number", AR: "رقم الهاتف" },
    Country_Code: { EN: "Country Code", AR: "رمز الدولة" },
    Location: { EN: "Location", AR: "الموقع" },
    At: { EN: "at", AR: "الساعة" },
    Received: { EN: "Received", AR: "طلبات من قبل الآخرين" },
    Hospital: { EN: "Hospital", AR: "المستشفى" },
    Appointment: { EN: "Appointment", AR: "موعد" },

    Days: { EN: "Days", AR: "أيام" },
    Day: { EN: "Day", AR: "يوم" },
    Minutes: { EN: "Minutes", AR: "دقائق" },
    Select_time: { EN: "Select time", AR: "اختر الوقت" },
    Select_location: { EN: "Select Location", AR: "اختر موقعا" },
    from: { EN: "from", AR: "من" },
    English: { EN: "English", AR: "الإنجليزية" },
    Arabic: { EN: "Arabic", AR: "العربية" },
    Resign: { EN: "Resign", AR: "تقديم الاستقالة" },

    // week name start
    Sun: { EN: "Sun", AR: "الأحد" },
    Mon: { EN: "Mon", AR: "الإثنين" },
    Tue: { EN: "Tue", AR: "الثلاثاء" },
    Wed: { EN: "Wed", AR: "الاربعاء" },
    Thu: { EN: "Thu", AR: "الخميس" },
    Fri: { EN: "Fri", AR: "الجمعة" },
    Sat: { EN: "Sat", AR: "السبت" },
    // week name end

    // month name
    January: { EN: "January", AR: "يناير" },
    February: { EN: "February", AR: "فبراير" },
    March: { EN: "March", AR: "مارس" },
    April: { EN: "April", AR: "أبريل" },
    May: { EN: "May", AR: "مايو" },
    June: { EN: "June", AR: "يونيو" },
    July: { EN: "July", AR: "يوليو" },
    August: { EN: "August", AR: "أغسطس" },
    September: { EN: "September", AR: "سبتمبر" },
    October: { EN: "October", AR: "أكتوبر" },
    November: { EN: "November", AR: "نوفمبر" },
    December: { EN: "December", AR: "ديسمبر" },

    Monthly: { EN: "Monthly", AR: "شهرياً" },
    Yearly: { EN: "Yearly", AR: "سنوياً" },

    // add schedule calender and datepicker
    I18N_VALUES: {
      EN: {
        weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      },
      AR: {
        weekdays: [
          "الإثنين",
          "الثلاثاء",
          "الاربعاء",
          "الخميس",
          "الجمعة",
          "السبت",
          "الأحد"
        ],
        months: [
          "يناير",
          "فبراير",
          "مارس",
          "أبريل",
          "مايو",
          "يونيو",
          "يوليو",
          "أغسطس",
          "سبتمبر",
          "أكتوبر",
          "نوفمبر",
          "ديسمبر"
        ]
      }
    },

    // hospital side
    Plan_Name: { EN: "Plan Name", AR: "اسم الباقة" },
    Price: { EN: "Price", AR: "السعر" },
    Subscription_Type: { EN: "Subscription Type", AR: "نوع الاشتراك" },
    Start_Date: { EN: "Start Date", AR: "تاريخ البدء" },
    End_Date: { EN: "End Date", AR: "تاريخ الانتهاء" },
    Permitted_Doctor: { EN: "Permitted Doctors", AR: "عدد الأطباء المسموح به" },

    // doctor module
    Complete_Your_Profile: {
      EN:
        "Please complete your profile in English and Arabic to proceed",
      AR:
        "الرجاء اكمال بيانات حسابك للمتابعة"
    },
    Enter_Contact_Detail_For: {
      EN: "Please enter your contact details for",
      AR: "الرجاء ادخال تفاصيل التواصل ل"
    },
    Enter_Contact_Detail: {
      EN: "Please Enter Your Contact Details",
      AR: "الرجاء ادخال تفاصيل التواصل"
    },
    Doctor_list: { EN: "Doctors' List", AR: "قائمة الأطباء" },
    Add_Doctor: { EN: "Add Doctor", AR: "أضف طبيباً" },
    Doctor: { EN: "Doctor", AR: "الطبيب" },
    Search_Doctor: { EN: "Search Doctors", AR: "البحث عن أطباء" },
    Search_Result: { EN: "Search Results", AR: "نتائج البحث" },
    Search: { EN: "Search", AR: "بحث" },
    Associated_Hospitals: {
      EN: "Associated Hospitals",
      AR: "المستشفيات المرتبطة"
    },
    View: { EN: "View", AR: "عرض" },
    Date_Created: { EN: "Date Created", AR: "تاريخ الإنشاء" },
    Employment_Date: { EN: "Employment Dates", AR: "تواريخ التوظيف" },
    years: { EN: "years", AR: "" },
    Doctor_Search_placeholder_by_name_email: {
      EN: "Search by name or email",
      AR: "البحث بالإسم او البريد الالكتروني"
    },
    Doctor_Search_placeholder: {
      EN: "Search by Name",
      AR: "البحث بالاسم"
    },
    Next_Available: { EN: "Next Available", AR: "الموعد المقبل" },
    Specialities: { EN: "Specialties", AR: "التخصصات" },
    Approval_Pending: { EN: "Awaiting Doctor", AR: "في انتظار الطبيب" },
    Request_Pending: { EN: "Awaiting Hospital", AR: "في انتظار المستشفى" },
    Already_Associated: { EN: "Already Associated", AR: "تم الربط سابقاً" },
    Accept_Doctor_Request: {
      EN: "Kindly respond to the doctor’s request",
      AR: "الرجاء الرد على طلب الطبيب"
    },
    Request_pending: { EN: "Request Pending", AR: "طلب معلق" },

    Not_accept_Yet: {
      EN: "The doctor hasn’t accepted the request yet",
      AR: "لم يقبل الطبيب الطلب بعد"
    },

    Accept_Hospital_Request: {
      EN: "Kindly respond to the hospital’s request",
      AR: "الرجاء الرد على طلب المستشفى"
    },

    Hospital_Not_accept_Yet: {
      EN: "The hospital hasn’t accepted the request yet",
      AR: "لم يقبل المستشفى الطلب"
    },


    Created_Date: { EN: "Created Date", AR: "تاريخ الإنشاء" },
    Select_date: { EN: "Select Date", AR: "حدد التاريخ" },
    Select_date_created: { EN: "Select Joining Date", AR: "اختر تاريخ الربط" },
    Select_Status: { EN: "Select Status", AR: "اختر الحالة" },
    Speciality: { EN: "Specialty", AR: "التخصص" },
    Select_Specialties: { EN: "Select Specialty", AR: "اختر التخصص" },
    Enter_Atleast_One: {
      EN: "Please enter at least one search criterion",
      AR: "الرجاء ادخال شروط البحث"
    },
    Filters: { EN: "Filters", AR: "تصفية النتائج" },
    Edit_Doctor: { EN: "Edit Doctor", AR: "تعديل ملف الطبيب" },
    Send_Request: { EN: "Send Request", AR: "إرسال الطلب" },
    Cancel_appointment_text: {
      EN: "Are you sure you want to cancel this appointment?",
      AR: "هل تريد إلغاء الموعد؟"
    },
    Cancel_Reason: { EN: "Cancellation reason", AR: "سبب الإلغاء" },
    Cancel_Reason_Placeholder: {
      EN: "The patient will be able to view this note",
      AR: "يستطيع المريض رؤية هذه الملاحظة"
    },
    Patient_Name: { EN: "Patient Name", AR: "اسم المريض" },
    Hospital_Name: { EN: "Hospital Name", AR: "اسم المستشفى" },
    Appointment_Date: { EN: "Appointment Date", AR: "تاريخ الموعد" },
    End: { EN: "End", AR: "النهاية" },
    Active: { EN: "Active", AR: "ساري" },
    Inactive: { EN: "Inactive", AR: "غير ساري" },
    appointment_on: { EN: "Appointment on", AR: "تاريخ الموعد" },
    Degree_01: {
      EN: "Harvard University in Cambridge, MA",
      AR: "جامعة هارفارد في كامبريدج ، ماساتشوستس"
    },
    Doctor_Name: { EN: "Doctor Name", AR: "اسم الطبيب" },
    Requestor_Name: { EN: "Requestor Name", AR: "اسم صاحب الطلب" },
    Acceptor_Name: { EN: "Acceptor Name", AR: "اسم قابل الطلب" },
    Resign_text: {
      EN: "Are you sure you want to resign from this hospital?",
      AR: "هل تريد الإستقالة من المستشفى؟"
    },

    // Add Insurance in hospital module

    Add_Insurance: { EN: "Add Insurance", AR: "اضافة شركة التأمين" },
    Insurance_List: { EN: "Insurance List", AR: "قائمة شركات التأمين" },
    Select_hospital: { EN: "Select Hospital", AR: "اختر المستشفى" },
    Select_Insurance: { EN: "Select Insurance", AR: "اختر شركة التأمين" },
    No_Insurance_Found: {
      EN: "No Insurance Found",
      AR: "لم يتم العثور على تأمين"
    },

    No_Record_Found: { EN: "No record found", AR: "لا توجد نتائج" },
    Select_days: { EN: "Select Days", AR: "حدد الأيام" },
    Select_days2: { EN: "Select days", AR: "حدد الأيام" },

    Start_Date_Greater_Validation: {
      EN: "Start date and/or time may not be in past",
      AR: "يجب الا يكون التاريخ او الوقت في الماضي"
    },
    Select_Days_Validation: {
      EN: "Please select at least one day",
      AR: "الرجاء اختيار يوماً واحداً على الأقل"
    },
    Schedule_Time_Duration_Validation: {
      EN: "Selected time should be greater than selected duration",
      AR: "يجب أن يكون الوقت المحدد أكبر من المدة المحددة"
    },

    End_Time_Greater_Than_Start_Time: {
      EN: "Start time should be before end time",
      AR: "يجب أن يكون وقت البدء قبل وقت الإنتهاء"
    },
    No_Hospital_Found: {
      EN: "No hospitals found",
      AR: "لا توجد مستشفيات"
    },
    No_Country_Found: {
      EN: "Country not found",
      AR: "لم نجد الدولة هذه"
    },

    No_Time_Found: { EN: "No Time Found", AR: "لا يوجد وقت" },
    Can_Not_Add_More_Then: {
      EN: "You can not add more then 10 certificates",
      AR: "لا يمكنك إضافة أكثر من 10 شهادات"
    },
    Copied: { EN: "Copied", AR: "تم النسخ" },
    Can_Not_Add_schedule_Fee_Required: {
      EN: "Cannot add schedules until the doctor adds his fees",
      AR: "لا يمكن إضافة الجداول قبل تحديد رسوم الاستشارة. الرجاء التواصل مع الطبيب(ة)"
    },
    Hospital_Has_Disable_This_Feature: {
      EN: "The hospital has disabled this feature, you are unable to create schedules",
      AR: " تم تعطيل هذه الخدمة من قبل المستشفى، لا يمكنك انشاء جدولك لهذه المستشفى"
    },
    No_Reviews: {
      EN: "No Reviews",
      AR: "لا تعليقات من مرضى آخرين"
    },

    D: { EN: "D", AR: "د" },
    H: { EN: "H", AR: "م" },
    P: { EN: "P", AR: "المريض" },

    Export_CSV: { EN: "Export CSV", AR: "تحميل البيانات" },
    Requested_Val: { EN: "Requested", AR: "مطلوب" },
    Please_Try_Again: { EN: "Something went wrong. Please try again", AR: "حصل خطأ ما. الرجاء إعادة المحاولة" },
    Session_Expired: { EN: "Session expired. Please log in again to continue", AR: "الرمز المميز غير صالح"},

    Married:{ EN: "Married", AR: "متزوج" },
    Single: { EN: "Single", AR: "اعذب" },
    Male: { EN: "Male", AR: "ذكر" },
    Female: { EN: "Female", AR: "انثى" },
    Other:  { EN: "Other", AR: "آخر" },
    CM:  { EN: "CM", AR: "سم" },
    KG:  { EN: "KG", AR: "كغ" },

    Language_Not_Updated: { EN: "Language not updated", AR: "لم يتم تغيير اللغة. الرجاء إعادة المحاولة" },
    };

  errorArabicTextMessage = {
    FIELD_REQUIRED: "هذه الخانة مطلوبه",
    MIN_REQUIRED_3: "الرجاء ادخال على الأقل 3 أحرف",
    MAX_LENGTH_20: "الرجاء ادخال ما لا يزيد عن 20 حرفاُ",
    MAX_LENGTH_200: "الرجاء ادخال ما لا يزيد عن 200 حرفاً أو رقماً",
    MAX_LENGTH_350: "لا يمكن أن يتجاوز هذا الحقل 350 حرفًا",
    MAX_LENGTH_250: "لا يمكن أن يتجاوز هذا الحقل 250 حرفًا",
    MAX_REQUIRED_20: "لا يمكن أن يتجاوز هذا الحقل 20 رقمًا",
    MAX_REQUIRED_25: "الرجاء ادخال ما لا يزيد عن 25 حرفاً",
    MAX_REQUIRED_30: "لا يمكن أن يتجاوز هذا الحقل 30 رقمًا",
    MAX_REQUIRED_50: "لا يمكن أن يتجاوز هذا الحقل 50 رقمًا",
    MAX_REQUIRED_100: "لا يمكن أن يتجاوز هذا الحقل 100 رقمًا",
    MAX_REQUIRED_150: "لا يمكن أن يتجاوز هذا الحقل 150 رقمًا",
    MAX_REQUIRED_500: "لا يمكن أن يتجاوز هذا الحقل 500 رقم",
    MAX_REQUIRED_255: "لا يمكن أن يتجاوز هذا الحقل 255 رقم",
    MIN_VALUE_1: "يجب أن يكون هذا الحقل 1 عدد لا يقل عن",
    MIN_VALUE_8: "يجب أن يكون هذا الحقل 8 عدد لا يقل عن",
    MIN_VALUE_9: "يجب أن يكون هذا الحقل 9 عدد لا يقل عن",
    MIN_VALUE_10: "يجب أن يكون هذا الحقل 10 عدد لا يقل عن",
    MAX_VALUE_5: "لا يمكن أن يتجاوز هذا الحقل 5 أرقا",
    MAX_VALUE_10: "لا يمكن أن يتجاوز هذا الحقل 10 أرقام",
    MAX_VALUE_12: "لا يمكن أن يتجاوز هذا الحقل 12 أرقام",
    MAX_VALUE_50: "لا يمكن أن يتجاوز هذا الحقل 50 أرقام",
    MAX_VALUE_100: "لا يمكن أن يتجاوز هذا الحقل 100 رقم",

    Validation_Email_Valid: "الرجاء ادخال بريد الكتروني صالح",
    WEBSITE_URL_VALID: "رابط الموقع غير صالح",
    ATLEAST_ONE_REQUIRED: "مطلوب حقل واحد على الأقل",

    CONTACT_PERSON_REQUIRED: "اسم شخص التواصل الزامي",
    HOSPITAL_NAME_REQUIRED: "اسم المستشفى الزامي",
    EMAIL_REQUIRED: "الرجاء ادخال عنوان البريد الالكتروني",
    PHONE_NUMBER_REQUIRED: "رقم الهاتف الزامي",
    CONTACT_ADDRESS_REQUIRED: "عنوان الاتصال الزامي",
    CITY_REQUIRED: "اسم المدينة الزامي",

    FIRST_NAME_REQUIRED: "الاسم الأول الزامي",
    LAST_NAME_REQUIRED: "اسم العائلة الزامي",
    EXPERIENCE_REQUIRED: "سنوات الخبرة الزامية",
    DEGREE_REQUIRED: "الدرجة المطلوبة",
    UNIVERSITY_REQUIRED: " الجامعة الزامية",
    CERTIFICATES_REQUIRED: "الشهادات مطلوبة",
    COUNTRY_REQUIRED: "الدولة مطلوبة"
  };

  constructor(public drawer: DrawerService) {
    Object.assign(this.pageCommonText, this.pageCommonText);
  }

  public labelGetter(LabelKey: any) {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      //this.drawer.selectedLanguage
      return this.pageCommonText[LabelKey][language];
    }
  }

  public arabicError(LabelKey: any) {
    return this.errorArabicTextMessage[LabelKey];
  }
  public errorGetter(LabelKey: any) {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      return error_msg[LabelKey][language];
    }
  }
  public currencyCode() {
    // for country code
    const storeData = JSON.parse(localStorage.getItem("currentUser"));
    if (storeData.currency) {
      return storeData.currency;
    } else {
      return "BHD";
    }
  }

  public getPatientStatusCustomName(currentState): string {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      if (this.pageCommonText[currentState]) {
        return this.pageCommonText[currentState][language];
      } else {
        return currentState;
      }
    } else {
      return currentState;
    }


  }
  public statusConvertLanguageWise(status) {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      if (this.pageCommonText[status]) {
        return this.pageCommonText[status][language];
      } else {
        return status;
      }
    } else {
      return status;
    }
  }

  public appointmentPreferanceStatusConvert(status) {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      if(status=="Requested"){
        status="Requested_Val";
      } else {
        status=status;
      }
      if (this.pageCommonText[status]) {
        return this.pageCommonText[status][language];
      } else {
        return status;
      }
    } else {
      return status;
    }
  }

  public shortingTable(fieldName) {
    const currentClass = $("#" + fieldName).attr("class");
    if (currentClass === "sorting desc") {
      // this.orderBy = "ASC";
      $("th span").removeClass();
      $("th span").addClass("sorting desc");
      $("#" + fieldName).removeClass();
      $("#" + fieldName).addClass("sorting asc");
      return "ASC";
    } else {
      $("th span").removeClass();
      $("th span").addClass("sorting desc");
      $("#" + fieldName).removeClass();
      $("#" + fieldName).addClass("sorting desc");
      // this.orderBy = "DESC";
      return "DESC";
    }
  }

  /*Check cancel by appointment  */
  public checkCancelBy(type) {
    if (type === "doctor") {
      return this.labelGetter("D");
    }
    if (type === "hospital") {
      return this.labelGetter("H");
    }
    if (type === "patient") {
      return this.labelGetter("P");

    }
    return type;
  }

  public dateForCSV(name) {
    return moment().format("YYYYMMDD") + "_ePointments_" + name;
  }
  /* For notification redirection  */

  public notificationRedirect(data, role) {
    const type = data.type;
    let redirectPage;

    if (role == "admin") {
      // return  redirectPage = "/admin/notifications";
      switch (type) {
        case "new_doctor_signup":
          redirectPage = "/admin/manage-doctor";
          break;
        case "new_patient_signup":
          redirectPage = "/admin/manage-patient";
          break;
        case "appointment_swapped":
          redirectPage = "/admin/swapped-appointments";
          break;
        case "new_appointment_scheduled":
          if (data.json_notification_data && data.json_notification_data.patient_id) {
            redirectPage = "/admin/manage-patient/patient-details/" + data.json_notification_data.patient_id + "/appointments";
          } else {
            redirectPage = "/admin/notifications";
          }
          break;

        case "appointment_canceled_by_patient":
          if (data.json_notification_data && data.json_notification_data.patient_id) {
            redirectPage = "/admin/manage-patient/patient-details/" + data.json_notification_data.patient_id + "/appointments";
          } else {
            redirectPage = "/admin/notifications";
          }

          break;
        case "appointment_rescheduled_by_patient":
          if (data.json_notification_data && data.json_notification_data.patient_id) {
            redirectPage = "/admin/manage-patient/patient-details/" + data.json_notification_data.patient_id + "/appointments";
          } else {
            redirectPage = "/admin/notifications";
          }
          break;
        case "appointment_canceled_by_doctor":
          if (data.json_notification_data && data.json_notification_data.hospital_id) {
            redirectPage = "/admin/manage-hospital/hospital-details/" + data.json_notification_data.hospital_id + "/appointments";
          } else {
            redirectPage = "/admin/notifications";
          }
          break;
        case "rating_and_reviews":
          if (data.json_notification_data && data.json_notification_data.doctor_id) {
            redirectPage = "/admin/manage-doctor/doctor-details/" + data.json_notification_data.doctor_id + "/ratting-reviews";
          } else {
            redirectPage = "/admin/notifications";
          }
          break;
        default:
          redirectPage = "/admin/notifications";
          break;
      }
    }

    if (role == "hospital") {
      switch (type) {
        case "new_doctor_request_received":
          redirectPage = "/hospital/doctors";
          break;
        case "appointment_canceled_by_doctor":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "appointment_canceled_by_patient":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "appointment_swapped":
          redirectPage = "/hospital/appointments/swapped-appointments";
          break;
        case "appointment_rescheduled_by_patient":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "new_appointment_request":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "doctor_accepted_appointment_request":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "doctor_rejected_appointment_request":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "new_appointment_scheduled":
          redirectPage = "/hospital/appointments/upcoming-appointments";
          break;
        case "new_doctor_accepted_request":
          if (data.json_notification_data && data.json_notification_data.doctor_id) {
            redirectPage = "/hospital/doctors/" + data.json_notification_data.doctor_id + "/profile-details";
          } else {
            redirectPage = "/hospital/notifications";
          }
          break;
        case "rating_and_reviews":
          if (data.json_notification_data && data.json_notification_data.doctor_id) {
            redirectPage = "/hospital/doctors/" + data.json_notification_data.doctor_id + "/rating-reviews";
          } else {
            redirectPage = "/hospital/notifications";
          }
          break;
        default:
          redirectPage = "/hospital/notifications";
          break;
      }
    }

    if (role == "doctor") {
      switch (type) {
        case "new_hospital_request":
          redirectPage = "/doctor/profile/locations";
          break;
        case "request_accepted":
          redirectPage = "/doctor/profile/locations";
          break;
        case "appointment_canceled_by_hospital":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "appointment_canceled_by_patient":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "new_appointment_request_for_doctor":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "appointment_canceled_by_patient":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "new_appointment_confirmed":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "reschedule_appointment":
          redirectPage = "/doctor/appointments/upcoming-appointments";
          break;
        case "rating_and_reviews":
          redirectPage = "/doctor/profile/ratting-reviews";
          break;

        default:
          redirectPage = "/doctor/notifications";
          break;
      }
    }

    return redirectPage;

    // if (data.type == "new_doctor_signup") {
    //   //  return "/admin/manage-doctor/doctor-details/"+ data.doctor_id +"/profile-details";
    //    return "/admin/manage-doctor";
    //  } else {
    //    return "/admin/notifications";
    //  }
  }
}
