import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { default_const } from '../constants/consts';



const dataName = default_const.currentUser;
const languageData = default_const.selectedLanguageData;

@Injectable()
export class TokenManager {
    constructor(private router: Router) {}

    fetchToken() {
        let token: any;
            const userData = localStorage.getItem(dataName);
            if (!userData) {
                return "";
            }
            try {
                if (isDevMode()) {
                    token = JSON.parse(userData)["token"];
                } else {
                    token = JSON.parse(userData)["token"];

                }
            } catch (err) {
                this.deleteData();
                this.router.navigate(["/"]);
            }

        return token;
    }

    deleteToken() {
            // Client only code.
            localStorage.removeItem(dataName);
    }

    fetchName() {
        let name: any;
            const userData = localStorage.getItem(dataName);
            if (!userData) {
                return null;
            }
            try {
                if (isDevMode()) {
                    name = JSON.parse(userData)["name"];
                } else {

                }
            } catch (err) {
                this.deleteData();
                this.router.navigate(["/"]);
            }

        return name;
    }

    fetchId() {
        let id: any;
            const userData = localStorage.getItem(dataName);
            if (!userData) {
                return null;
            }
            try {
                if (isDevMode()) {
                    id = JSON.parse(userData)["id"];
                } else {

                }
            } catch (err) {
                this.deleteData();
                this.router.navigate(["/"]);
            }
        return id;
    }

    storeData(value: any) {
        if (value) {
            localStorage.setItem(dataName, JSON.stringify(value));
        }
    }

    deleteData() {
        localStorage.removeItem(dataName);
       // localStorage.removeItem(languageData);
    }

    //#endregion

    fetchDataByKey(key: any) {
            const userData = localStorage.getItem(key);
            if (!userData) {
                return null;
            }
            try {
                return JSON.parse(userData);
            } catch (err) {
                //this.deleteData();
                this.router.navigate(["/"]);
            }
    }

    storeDataByKey(key: any, value: any) {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    deleteDataByKey(key) {
        localStorage.removeItem(key);
    }
}
