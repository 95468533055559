import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommonLangService } from '@app/language/common/common-lang.service';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html'
})
export class ConfirmBoxComponent implements OnInit {

  subject: Subject<boolean>;
  constructor(public langService:CommonLangService, private activeModal: NgbModal) { }

  ngOnInit() {
  }

  action(value: boolean) {
    this.activeModal.dismissAll();
    this.subject.next(value);
    this.subject.complete();
  }

}
