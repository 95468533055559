import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {
  transform(val, args) {
    if(val) {
    let newVal = "";
    newVal = val.toString();
    let newStr = "";
    let i = 0;

    if (newVal.length === 8 || newVal.length === 12) {
      for (i = 0; i < (Math.floor(newVal.length / 4)); i++) {
        newStr = newStr + newVal.substr(i * 4, 4) + "  ";
      }
      return newStr + newVal.substr(i * 4);


    }
    if (newVal.length === 9) {
      for (i = 0; i <= 3; i++) {
        if (i <= 1) {
          newStr = newStr + newVal.substr(i * 2, 2 + i) + "  ";
        }
      }
      return newStr + newVal.substr(5);


    }
    if (newVal.length === 10) {
      for (i = 0; i <= 3; i++) {
        if (i <= 1) {
          newStr = newStr + newVal.substr(i * 2, 2 + i + i) + "  ";
        }
      }
      return newStr + newVal.substr(6);


    }


    if (newVal.length === 11) {
      for (i = 0; i <= 3; i++) {
        if (i <= 1) {
          newStr = newStr + newVal.substr(i * 3, 3 + i) + "  ";
        }
      }
      return newStr + newVal.substr(7);

    }


    return newVal;
  }
  }
}
