import { Component, OnInit, Input } from "@angular/core";
import { DrawerService } from "../../services/drawer.service";
import { CommonLangService } from "src/app/language/common/common-lang.service";

@Component({
  selector: "app-manage-no-data",
  template: `
        <div class="alert no-data alert-danger">{{ message || "No Data Found" }}</div>
    `,
  providers: []
})
export class NoDataComponent implements OnInit {
  @Input()
  message: any = "No Data Found";

  ngOnInit() { }

  constructor(public drawer: DrawerService, public langService: CommonLangService) {
    this.drawer.languageChangeData$.subscribe({ // for update list in language wise
      next: (result: any) => {
        this.message = this.langService.labelGetter("NO_DATA_FOUND");
      }
    });
  }

}
