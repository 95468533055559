import { NgModule } from "@angular/core";

import { OnFocusDirective } from "./on-focus.directive";

import { InputFocusDirective } from "./input-focus.directive";

@NgModule({
    imports: [],
    declarations: [OnFocusDirective, InputFocusDirective],
    providers: [],
    exports: [OnFocusDirective, InputFocusDirective]
})
export class GlobalDirectivesModule {}
