import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DrawerService } from './shared/services/drawer.service';
import { TokenManager } from './shared/services/token-manager.service';
import { ApiHandler } from './shared/services/api-handler.service';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ToasterService } from "./shared/services/toaster.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TokenInterceptor } from './app.interceptor';
import { SharedModule } from './shared/module/shared.module';
import { NotFoundComponent } from './shared/component/not-found/not-found.component';
import {NgxPaginationModule} from "ngx-pagination"; // <-- import the module
import { ConfirmBoxComponent } from './shared/confirm-box/confirm-box.component';



const toastConfig = {
  timeOut: 3000,
  closeButton: false,
  enableHtml: true,
  progressBar: false,
  maxOpened: 1,
  autoDismiss: true,
  preventDuplicates: true,
  positionClass: 'toast-bottom-center'
};



@NgModule({
  declarations: [
    AppComponent,NotFoundComponent, ConfirmBoxComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, NgSelectModule, FormsModule,
    SharedModule,
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(toastConfig),
    NgxPaginationModule

  ],
  providers: [DrawerService,
    TokenManager,
    ApiHandler,
    ToasterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents:[ConfirmBoxComponent]
})
export class AppModule { }
