import { Component,Inject,PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { MomentDateFormatter } from "./date-format";
import { AuthService } from "./auth/services/auth.service";
import { DrawerService } from "./shared/services/drawer.service";
import { TokenManager } from "./shared/services/token-manager.service";
import { isPlatformBrowser } from "@angular/common";
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",

  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }]
})
export class AppComponent {
  title = "epointments";
  ddlSelectedValue: any;
  constructor(private router: Router, private auth: AuthService,
    public drawer: DrawerService, private token: TokenManager,
     @Inject(PLATFORM_ID) private platformId: Object) {
    // this.listData();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url === "/" || event.url === "/login" || event.url === "/admin" || event.url === "/doctor" || event.url === "/hospital"
          || event.url === "/forgot-password") {
          if (this.auth.isLoggedIn()) {
           // this.getNotificationUnreadCount();
            const user = this.token.fetchDataByKey("currentUser").user_type;
            this.router.navigate(["/" + user + "/dashboard"]);
            return false;
          }
        } else {
          if (this.auth.isLoggedIn()) {
            this.getNotificationUnreadCount();
          }
        }
      }
      if (event instanceof NavigationEnd) {
        const url = this.router.url;
        window.scrollTo({ top: 0 });
        this.modalClose();
      }
    });
    // if (this.auth.isLoggedIn() === true) {
    //    this.router.navigate(["/admin/dashboard"]);
    // }
  }

  getNotificationUnreadCount() {
    this.auth.notificationUnreadCountAPI().subscribe({
      next: (result: any) => {
        if (result.success) {
          this.auth.notificationUnreadCount.next(result.data);
        }

      },
      error: err => {

      }
    });
  }
   modalClose() {
     if (isPlatformBrowser(this.platformId)) {
      $('.modal').modal({
        show:false,
        keyboard: false,
        backdrop: 'static'
      });
    }
  }
}
