import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { DatePipe } from '@angular/common';

// dateString: mm-dd-yyyy

@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  parse(dateString): NgbDateStruct {
    const dateArray = dateString.split('-');
    return {
      month: dateArray[0],
      day: dateArray[1],
      year: dateArray[2]
    };
  }

  format(date: NgbDateStruct) {
    return `${date.month}-${date.day}-${date.year}`;
  }
}

export class MyDate {
  newDate: Date;

  constructor(date?: string) {
    if (date) {
      this.newDate = new Date(date);
    } else {
      this.newDate = new Date();
    }
  }

  getFullYear(): number {
    return this.newDate.getUTCFullYear();
  }

  getMonth(): number {
    return this.newDate.getUTCMonth();
  }

  getDate(): number {
    return this.newDate.getUTCDate();
  }
}

@Pipe({ name: 'mydate' })
export class MyDatePipe implements PipeTransform {
  oldDatePipe: DatePipe;

  constructor() {
    this.oldDatePipe = new DatePipe('en-US');
  }

  transform(value: string, args: string): any {
    try {
      // const trimmedTimeZoneVal = value.split("T")[0];
      // return this.oldDatePipe.transform(trimmedTimeZoneVal, args);

      return this.oldDatePipe.transform(value, args);
    } catch (ex) {
      // do nothing
    }

    return null;
  }
}

@Pipe({ name: 'mydatetime' })
export class MyDateTimePipe implements PipeTransform {
  oldDatePipe: DatePipe;

  constructor() {
    this.oldDatePipe = new DatePipe('en-US');
  }

  transform(value: string, args: string): any {
    try {
      // const trimmedTimeZoneVal = value.split("T")[1];
      // return trimmedTimeZoneVal.split(".")[0];
      const appliedDateTime = this.oldDatePipe.transform(value, args);
      return appliedDateTime;
    } catch (ex) {
      //   return "00:00";
    }

    return null;
  }
}

// export function timeZoneInfoTrimmer(date: string) {
//     let newDate: any = date.split('T');
//     newDate = newDate[0];
//     return newDate;
// }
