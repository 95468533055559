import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { TokenManager } from "./token-manager.service";
import { default_const } from "../constants/consts";
import { ApiHandler } from "./api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DrawerService {
  selectedLanguage: any;
  languageChange$: BehaviorSubject<string>;
  languageChangeData$: BehaviorSubject<string>;
  globalImageData$: BehaviorSubject<string>; // for header global image
  body: HTMLBodyElement;
  htmlRoot: HTMLElement;
  selectedLanguageId: any;
  selectedLanguageData: any;

  constructor(private router: Router, private token: TokenManager, public apiHandler: ApiHandler) {

    this.body = document.getElementsByTagName("body")[0];
    this.htmlRoot = document.getElementsByTagName("html")[0];
    this.selectedLanguage = default_const.Default_Language["key"];
    //

    if (localStorage.getItem("selectedLanguage")) {
      this.selectedLanguage = JSON.parse(localStorage.getItem("selectedLanguage"));
    }
    if (localStorage.getItem("selectedLanguageData")) {
      this.selectedLanguageData = JSON.parse(localStorage.getItem("selectedLanguageData"));
    }


    // const getLangKey = this.selectedLanguage;
    const getLangKey = this.selectedLanguage;
    if (getLangKey) {
      this.selectedLanguage = getLangKey;
    }
    this.languageChange$ = new BehaviorSubject(this.selectedLanguage);
    this.languageChangeData$ = new BehaviorSubject(this.selectedLanguageData);
    this.globalImageData$ = new BehaviorSubject("");

    // ============== language change data========
    this.languageChangeData$.subscribe({
      next: (result: any) => {
        this.selectedLanguageData = result;
        // for arabic language only
        //  console.log(result)
        this.token.storeDataByKey(default_const.selectedLanguageData, this.selectedLanguageData);

        if (this.selectedLanguageData) {
          if (this.selectedLanguageData.name === "AR") {
            this.body.classList.add("arabic-lang");
            this.htmlRoot.setAttribute("lang", "ar");
            this.htmlRoot.setAttribute("dir", "rtl");
          } else {
            this.body.classList.remove("arabic-lang");
            this.htmlRoot.setAttribute("lang", "en");
            this.htmlRoot.removeAttribute("dir");
          }
        }
      },
      //  error: err => console.log(err)
    });
  }
  setAndUpdatelanguageSelction(dataObj: any, data: any) {
    this.selectedLanguage = dataObj;
    this.selectedLanguageData = data;
    this.languageChange$.next(dataObj);
    this.languageChangeData$.next(data);



  }

  updateLanguage(formData) {
    return this.apiHandler.apiPost(API.COMMON_ENDPOINT.UPDATE_LANGUAGE, formData);
  }

  getLanguageList() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_ALL_LANGUAGE_LIST);
  }

}
