import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "startsWith" })
// tslint:disable-next-line:class-name
export class startsWithPipe implements PipeTransform {
  transform(value: any[], term: string): any[] {
    // tslint:disable-next-line:curly
    if (!term) return value;
    return value.filter((x: any) => x.name.toLowerCase().startsWith(term.toLowerCase())
      || x.email.toLowerCase().startsWith(term.toLowerCase()));

  }
}
