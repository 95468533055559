import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/component/not-found/not-found.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: "./auth/auth.module#AuthModule"
  },
  {
    path: "admin",
    loadChildren: "./auth/auth.module#AuthModule"
  },
   {
    path: "",
    loadChildren: "./frontend/frontend.module#FrontendModule"
   },
   {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule"
   },
   {
     path: "**",
     component: NotFoundComponent
   }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
