/**
  * Usage: dateString | localDate:'format'
  * Have to add in  share module with
  * import following below in share module
  * import localeAr from '@angular/common/locales/ar'; // for calender arabic and english
  * import localeEn from '@angular/common/locales/en'; // for calender arabic and english
  *  import { registerLocaleData } from '@angular/common'; // for calender arabic and english
  *  registerLocaleData(localeAr); // for calender arabic and english
  *  registerLocaleData(localeEn); // for calender arabic and english
 **/

import { Pipe, PipeTransform, OnDestroy } from "@angular/core";
import { formatDate } from "@angular/common";
import { DrawerService } from ".././services/drawer.service";
import * as moment from "moment";

@Pipe({
    name: "dateTranslation",
    pure: false
})
export class DateConversionPipe implements PipeTransform {
  currentLan: any;
  dateLang: any;
    constructor(public drawer: DrawerService) {
    }

    // transform(value: any, format: string) {
    //     if (!value) { return ""; }

    //     if (this.dateLang == "EN") {
    //       if (!format) { format = "mediumDate"; }
    //     } else {
    //       if (!format) { format = "longDate"; }
    //     }
    //     this.currentLan = this.dateLang;
    //     return formatDate(value, format, this.dateLang);

    // }
    transform(value: any, DateType: any, format: string) {
      this.dateLang = JSON.parse(localStorage.getItem("selectedLanguageData")).name;
      if (!value) { return ""; }
      if (this.dateLang == "EN") { // for english
        if (DateType == "date") {
          if (!format) { format = "mediumDate"; } // for display only date with formate Dec 13, 2019
        } else if (DateType=="time") {
          if (!format) { format = " h:mm a"; } // for display only time
        } else {
        if (!format) { format = "MMM dd, yyyy 'at'  h:mm a"; } // by default date formate
        }
      } else { // for arabic
        if (DateType == "date") {
          if (!format) { format = "longDate"; } // for display only date with formate Dec 13, 2019 for arabic
        } else if (DateType == "time") {
          if (!format) { format = " h:mm a"; } // for display only time for arabic
        } else {
        if (!format) { format = "MMM dd، yyyy 'الساعة'  h:mm a"; } // by default date formate for arabic
        }
      }
      this.currentLan=this.dateLang;
      value= moment(value).format("YYYY/MM/DD h:mm a");
      return formatDate(value, format, this.dateLang);

  }


}
