import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from "rxjs";
import "rxjs";
import { ApiHandler } from 'src/app/shared/services/api-handler.service';
import { API } from 'src/environments/environment';
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import { TokenManager } from 'src/app/shared/services/token-manager.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { CommonLangService } from 'src/app/language/common/common-lang.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  notificationUnreadCount: BehaviorSubject<any>;

  constructor(    private apiHandler: ApiHandler,
    private http: Http,
    private manageToken: TokenManager,
    public router: Router,
    public toasterService: ToasterService,
    public langService: CommonLangService,
    public drawer: DrawerService
        ) {
          this.notificationUnreadCount = new BehaviorSubject("");

        }

    isLoggedIn() {
      // TODO: need to add logic to invalidate token
      if (this.manageToken.fetchToken()) {
        return true;
      }
    }
    checkUser(){
      if (this.manageToken.fetchDataByKey("currentUser")) {
        return this.manageToken.fetchDataByKey("currentUser").user_type;
      }

    }



  loginUser(userName: string, password: string): Observable<any> {
    // Frame JSON body
    const loginReqBody = {
      email: userName,
      password: password,
      // device_type: "web",
      // device_id: "",
      // environment: "dev"
    };
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.LOGIN, loginReqBody).pipe(
      tap((response: any) => {
        const data = response.data;
        const token = data.access_token;
        if (token) {
          if(data.other_detail){
            this.manageToken.storeData({
              user_type: data.role,
              token: token,
              first_name: data.first_name,
              last_name: data.last_name,
              id: data.id,
              role_id: data.other_detail.id,
              currency: data.currency,
             // image: data.other_detail.hospital_user_detail.image_url,
            });

            if (data.other_detail.hospital_user_detail) {     // store  image for hospital
                this.drawer.globalImageData$.next(data.other_detail.hospital_user_detail.image_url);
                this.manageToken.storeDataByKey("userImage", data.other_detail.hospital_user_detail.image_url);

            }
            if (data.other_detail.doctor_user_detail) {     // store image for doctor
              this.drawer.globalImageData$.next(data.other_detail.doctor_user_detail.image_url);
              this.manageToken.storeDataByKey("userImage", data.other_detail.doctor_user_detail.image_url);
          }

          } else {
            this.manageToken.storeData({
              user_type: data.role,
              token: token,
              first_name: data.first_name,
              last_name: data.last_name,
              id: data.id,
            });
          }
        }
      })
    );

  }
  forgetPassword(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.FORGOT_PASSWORD, formData);
  }

  resetPassword(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.RESET_PASSWORD, formData);
  }
  hospitalSetPassword(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.HOSPITAL_SET_PASSWORD, formData);
  }
  checkHospitalAccount(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.CHECK_HOSPITAL_ACCOUNT, formData);
  }

  doctorAccountVerification(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.DOCTOR_ACCOUNT_VERIFICATION, formData);
  }

  hospitalList() {
    return this.apiHandler.apiGet(API.AUTH_ENDPOINTS.HOSPITAL_LIST);
  }
  signUp(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.DOCTOR_SIGN_UP, formData);
  }
  pageDeatil(language, slug){
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_PAGE_DETAIL(language, slug ));

  }
  logout() {
    // const logoutReqBody = {
    //   access_token: this.manageToken.fetchToken()
    // }
    return this.apiHandler.apiGet(API.AUTH_ENDPOINTS.LOGOUT);
  }
  countryCodeList() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.COUNTRIES_LIST);
  }
  countryList(type) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.COUNTRIES_DATA_LIST(type));
  }
  notificationUnreadCountAPI(){
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.NOTIFICATION_UNREAD_COUNT);

  }
}
