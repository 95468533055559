import { Injectable, isDevMode } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { TokenManager } from "../app/shared/services/token-manager.service";
import { ToasterService } from "../app/shared/services/toaster.service";
import { notification_msg, default_const } from "../app/shared/constants/consts";
import { throwError } from "rxjs";
import { tap } from 'rxjs/operators';
import { DrawerService } from './shared/services/drawer.service';
import * as moment from "moment-timezone";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonLangService } from './language/common/common-lang.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenManager: TokenManager,
    private toaster: ToasterService,
    public drawer: DrawerService,
    private ngbModal: NgbModal,
    public langService: CommonLangService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any>;

    // ==========language code for header====================
    let languageCode;
    const timeZone = moment.tz.guess() || "";

    const dataLanguage = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (dataLanguage) {
      languageCode = dataLanguage.name;
    } else {
      languageCode = default_const.Default_Language.key;
    }

    let head: any = {
      "access-token": this.tokenManager.fetchToken(),
      "language-code": languageCode,
      "timezone": timeZone


    };
    if (!isDevMode()) {
      head = Object.assign(
        {},
        {

          "access-token": this.tokenManager.fetchToken(),
          "language-code": languageCode,
          "timezone": timeZone

        }
      );
    }
    newReq = request.clone({
      setHeaders: head
    });

    return next.handle(newReq).pipe(
      tap(
        event => { },
        err => {
          console.log(err);
          this.ngbModal.dismissAll();
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.toaster.Error(this.langService.labelGetter("Session_Expired"));
            this.tokenManager.deleteToken();
            this.router.navigate(["/"]);
            // return Observable.empty();
          } else {
            // this.toaster.Error("Please try again.");
          }
        }
      )
    );
  }
}
