// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

const base_url = "https://epointments-api.codiantdev.com";
//const base_url = "http://localhost/epointment-api";


class Endpoints {
  AUTH_BASE = base_url + "/api";

  AUTH_ENDPOINTS = {
    LOGIN: this.joinPaths(this.AUTH_BASE, "login"),
    HOSPITAL_LIST: this.joinPaths(this.AUTH_BASE, "doctor/hospitals-list"),
    DOCTOR_SIGN_UP: this.joinPaths(this.AUTH_BASE, "doctor/register"),
    FORGOT_PASSWORD: this.joinPaths(this.AUTH_BASE, "forgot-password"),
    RESET_PASSWORD: this.joinPaths(this.AUTH_BASE, "reset-password"),
    HOSPITAL_SET_PASSWORD: this.joinPaths(this.AUTH_BASE, "hospital/set-password"),
    CHECK_HOSPITAL_ACCOUNT: this.joinPaths(this.AUTH_BASE, "hospital/check-hospital-account"),
    DOCTOR_ACCOUNT_VERIFICATION: this.joinPaths(this.AUTH_BASE, "verify-email-address"),
    LOGOUT: this.joinPaths(this.AUTH_BASE, "logout"),
  };
  USER_ENDPOINTS = {
    CHANGE_PASSWORD: this.joinPaths(this.AUTH_BASE, "change-password"),
    APPOINTMENT_SETTING: this.joinPaths(this.AUTH_BASE, "doctor/update-appointment-setting")
  };
  COMMON_ENDPOINT = {
    SUBSCRIPTIONS_LIST: this.joinPaths(this.AUTH_BASE, "subscriptions"),
    COUNTRIES_LIST: this.joinPaths(this.AUTH_BASE, "countries"),
    COUNTRIES_DATA_LIST:type =>  this.joinPaths(this.AUTH_BASE, "country-list?type="+type),
    USER_SETTING_LIST: this.joinPaths(this.AUTH_BASE, "settings"),
    GET_SPECIALTIES_LIST: this.joinPaths(this.AUTH_BASE, "specialities"),
    GET_ALL_LANGUAGE_LIST: this.joinPaths(this.AUTH_BASE, "languages"),
    UPDATE_LANGUAGE: this.joinPaths(this.AUTH_BASE, "change-language"),
    COMMON_HOSPITAL_LIST: this.joinPaths(this.AUTH_BASE, "hospitals"),
    HOSPITAL_LIST: (page, queryText, userId, type) =>
      this.joinPaths(this.AUTH_BASE, "doctor-hospital", userId + "?type=" + type + "&page=" + page + "&name=" + queryText),
    GET_DOCTOR_INSURANCE_LIST: (page, queryText, userId, hospitalId?) => this.joinPaths(this.AUTH_BASE, "doctor-insurance",
     userId+"?hospital_id=" + (hospitalId?hospitalId:"") + "&page=" + page + "&name=" + queryText),
    GET_DOCTOR_RATING_LIST: (page, id) => this.joinPaths(this.AUTH_BASE, "doctor-rating", id
      + "?page=" + page),
    NOTIFICATION_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE, "notification?page=" + page + "&name=" + queryText),
    DELETE_NOTIFICATION: this.joinPaths(this.AUTH_BASE, "delete-notification"),
    GET_PATIENT_DETAIL: (patientId, appointmentId) => this.joinPaths(this.AUTH_BASE, "patient-detail", patientId, appointmentId),
    GET_PAGE_DETAIL: (language, slug) =>this.joinPaths(this.AUTH_BASE, "page-detail", language, slug),
    NOTIFICATION_UNREAD_COUNT: this.joinPaths(this.AUTH_BASE, "notification-count"),
    GET_EXPORT_CSV: type => this.joinPaths(this.AUTH_BASE, "admin/download-file?type=" + type),
    GET_HOSPITAL_APPOINTMENT_EXPORT_CSV: id => this.joinPaths(this.AUTH_BASE, "admin/download-hospital-appointment" , id),


  };
  ADMIN_ENDPOINT = {
    GET_DASHBOARD: this.joinPaths(this.AUTH_BASE, "admin/dashboard"),
    DASHBOARD_CHART: type => this.joinPaths(this.AUTH_BASE, "admin/dashboard-chart?type=" + type),


    ADD_SPECIALTIES: this.joinPaths(this.AUTH_BASE, "admin/add-speciality"),
    DELETE_SPECIALTIES: id => this.joinPaths(this.AUTH_BASE, "admin/delete-speciality", id),
    SPECIALTIES_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE, "admin/specialities?page=" + page + "&name=" + queryText),
    GET_SPECIALTIES: id => this.joinPaths(this.AUTH_BASE, "admin/speciality", id),
    UPDATE_SPECIALTIES: this.joinPaths(this.AUTH_BASE, "admin/update-speciality"),
    UPDATE_SPECIALTIES_STATUS: this.joinPaths(this.AUTH_BASE, "admin/update-speciality-status"),

    SUBSCRIPTION_PLAN_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "admin/subscription-plans?page=" + page + "&name=" + queryText),
    ADD_SUBSCRIPTION_PLAN: this.joinPaths(this.AUTH_BASE, "admin/add-subscription"),
    DELETE_SUBSCRIPTION: id => this.joinPaths(this.AUTH_BASE, "admin/delete-subscription", id),
    GET_SUBSCRIPTION: id => this.joinPaths(this.AUTH_BASE, "admin/subscription-plan", id),
    UPDATE_SUBSCRIPTION: this.joinPaths(this.AUTH_BASE, "admin/update-subscription"),
    ASSIGN_SUBSCRIPTION_PLAN: this.joinPaths(this.AUTH_BASE, "admin/assign-plan"),
    GET_PAY_AS_YOU_GO_PRICE: this.joinPaths(this.AUTH_BASE, "admin/pay-as-you-go"),



    INSURANCES_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "admin/insurances?page=" + page + "&name=" + queryText),
    ADD_INSURANCE: this.joinPaths(this.AUTH_BASE, "admin/add-insurance"),
    UPDATE_INSURANCE_STATUS: this.joinPaths(this.AUTH_BASE, "admin/update-insurance-status"),
    DELETE_INSURANCE: id => this.joinPaths(this.AUTH_BASE, "admin/delete-insurance", id),
    GET_INSURANCE: id => this.joinPaths(this.AUTH_BASE, "admin/insurance", id),
    UPDATE_INSURANCE: this.joinPaths(this.AUTH_BASE, "admin/update-insurance"),

    HOSPITAL_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE, "admin/hospitals?page=" + page + "&name=" + queryText),
    ADD_HOSPITAL: this.joinPaths(this.AUTH_BASE, "admin/add-hospital"),
    GET_HOSPITAL: id => this.joinPaths(this.AUTH_BASE, "admin/hospital", id),
    UPDATE_HOSPITAL: this.joinPaths(this.AUTH_BASE, "admin/update-hospital"),
    DELETE_HOSPITAL: id => this.joinPaths(this.AUTH_BASE, "admin/delete-hospital", id),
    UPDATE_HOSPITAL_STATUS: id => this.joinPaths(this.AUTH_BASE, "admin/update-hospital-status", id),
    HOSPITAL_SUBSCRIPTIONS_PLAN_LIST: (page, queryText, id) => this.joinPaths(this.AUTH_BASE,
      "admin/hospital-subscription-plans", id + "?page=" + page + "&name=" + queryText),
    DELETE_HOSPITAL_SUBSCRIPTIONS_PLAN: id => this.joinPaths(this.AUTH_BASE, "admin/delete-hospital-subscription-plans", id),
    HOSPITAL_DOCTOR_LIST: (page, queryText, id) => this.joinPaths(this.AUTH_BASE,
      "admin/hospital-doctors", id + "?page=" + page + "&name=" + queryText),
    HOSPITAL_INVOICE_LIST: (page, queryText, id) => this.joinPaths(this.AUTH_BASE,
      "admin/hospital-subscription-invoice", id + "?page=" + page + "&name=" + queryText),
    UPDATE_INVOICE_STATUS: id => this.joinPaths(this.AUTH_BASE, "admin/change-invoice-status", id),
    HOSPITAL_APPOINTMENT_LIST: (page, queryText, id) => this.joinPaths(this.AUTH_BASE,
      "admin/hospital-appointments", id + "?page=" + page + "&patient_name=" + queryText),
      DELETE_MULTIPLE_HOSPITAL: this.joinPaths(this.AUTH_BASE, "admin/delete_multiple_hospital"),

    FAQ_LIST: (queryText) =>
      this.joinPaths(this.AUTH_BASE, "admin/faq?search=" + queryText),
    ADD_FAQ: this.joinPaths(this.AUTH_BASE, "admin/add-faq"),
    DELETE_FAQ: id => this.joinPaths(this.AUTH_BASE, "admin/delete-faq", id),
    UPDATE_FAQ_STATUS: (id, status) => this.joinPaths(this.AUTH_BASE, "admin/update-status", id + "?status=" + status),
    GET_FAQ: id => this.joinPaths(this.AUTH_BASE, "admin/faq-detail", id),
    UPDATE_FAQ: id => this.joinPaths(this.AUTH_BASE, "admin/update-faq-detail", id),


    SWAPPED_APPOINTMENT_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE,
      "admin/swap-appointments?page=" + page + "&name=" + queryText),


    PATIENT_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE,
      "admin/patients?page=" + page + "&patient_name=" + queryText),
    PATIENT_DETAIL: (id, type) => this.joinPaths(this.AUTH_BASE, "admin/patient-detail", id + "?type=" + type),
    PATIENT_APPOINTMENT_LIST: (page, queryText, id) => this.joinPaths(this.AUTH_BASE,
      "admin/patient-appointment", id + "?page=" + page + "&hospital_name=" + queryText),
    PATIENT_APPOINTMENT_DETAIL: (patientId, appointmentId) => this.joinPaths(this.AUTH_BASE,
      "admin/patient-appointment-detail", patientId, appointmentId),
    UPDATE_PATIENT_STATUS: (id, status) => this.joinPaths(this.AUTH_BASE, "admin/patient-update-status", id + "?status=" + status),


    DOCTOR_LIST: (page, queryText) => this.joinPaths(this.AUTH_BASE,
      "admin/doctors?page=" + page + "&docter_name=" + queryText),
    DELETE_DOCTOR: id => this.joinPaths(this.AUTH_BASE, "admin/delete-doctor", id),
    GET_DOCTOR_PROFILE: id => this.joinPaths(this.AUTH_BASE, "admin/doctor-profile", id),

    CMS_LIST: (page) => this.joinPaths(this.AUTH_BASE, "admin/cms-pages?page=" + page + "&language_code=en"),
    GET_CMS: id => this.joinPaths(this.AUTH_BASE, "admin/page-detail", id),
    UPDATE_CMS: id => this.joinPaths(this.AUTH_BASE, "admin/update-page-detail", id),
    DELETE_RATING: id => this.joinPaths(this.AUTH_BASE, "admin/delete-rating", id),



  };

  DOCTOR_ENDPOINT = {
    DASHBOARD_CHART: type => this.joinPaths(this.AUTH_BASE, "doctor/dashboard-chart?type=" + type),
    GET_DOCTOR_PROFILE: (type, languageCode) => this.joinPaths(this.AUTH_BASE, "doctor/profile-detail", type, languageCode),
    UPDATE_DOCTOR_PROFILE: this.joinPaths(this.AUTH_BASE, "doctor/update-personal-detail"),
    ADD_EDUCATION: this.joinPaths(this.AUTH_BASE, "doctor/add-education-detail"),
    DELETE_EDUCATION: id => this.joinPaths(this.AUTH_BASE, "doctor/remove-doctor-education", id),
    GET_EDUCATION_DETAIL: (id, languageCode) => this.joinPaths(this.AUTH_BASE, "doctor/get-education-detail", id, languageCode),
    UPDATE_EDUCATION: this.joinPaths(this.AUTH_BASE, "doctor/update-education-detail"),
    ADD_CERTIFICATION: this.joinPaths(this.AUTH_BASE, "doctor/add-certification-detail"),
    DELETE_CERTIFICATION: id => this.joinPaths(this.AUTH_BASE, "doctor/remove-doctor-certification", id),
    DELETE_OTHER: id => this.joinPaths(this.AUTH_BASE, "doctor/remove-other-detail", id),
    GET_CERTIFICATION_DETAIL: (id) => this.joinPaths(this.AUTH_BASE, "doctor/get-certification-detail", id),
    UPDATE_CERTIFICATION: this.joinPaths(this.AUTH_BASE, "doctor/update-certification-detail"),
    UPLOAD_DOCTOR_PROFILE_PICTURE: this.joinPaths(this.AUTH_BASE, "doctor/upload-profile-image"),
    GET_DOCTOR_COMPLETE_PROFILE: this.joinPaths(this.AUTH_BASE, "doctor/profile"),
    REMOVE_PROFILE_IMAGE: this.joinPaths(this.AUTH_BASE, "doctor/remove-profile-image"),
    ADD_SPECIALTIES: this.joinPaths(this.AUTH_BASE, "doctor/add-specialties"),
    DELETE_SPECIALTIES: id => this.joinPaths(this.AUTH_BASE, "doctor/remove-doctor-specialties", id),
    APPOINTMENT_LIST: (type, page, queryText) => this.joinPaths(this.AUTH_BASE, "doctor/appointment?type=" + type
      + "&page=" + page + "&patient_name=" + queryText),
    UPDATE_APPOINTMENT_STATUS: this.joinPaths(this.AUTH_BASE, "doctor/accept-reject-appointment-request"),
    CANCEL_APPOINTMENT: this.joinPaths(this.AUTH_BASE, "doctor/cancel-appointment"),

    UPDATE_HOSPITAL_STATUS: this.joinPaths(this.AUTH_BASE, "doctor/change-status"),
    ADD_UPDATE_FEE: id => this.joinPaths(this.AUTH_BASE, "doctor/update-fees", id),
    GET_ALL_INSURANCE_LIST: this.joinPaths(this.AUTH_BASE, "doctor/insurance"),
    ADD_INSURANCE: this.joinPaths(this.AUTH_BASE, "doctor/add-insurance"),
    UPDATE_HOSPITAL_DOCTOR: id => this.joinPaths(this.AUTH_BASE, "doctor/update-doctor-hospital-detail", id),


    DELETE_INSURANCE: id => this.joinPaths(this.AUTH_BASE, "doctor/delete-insurance", id),
    UPDATE_NOTE: id => this.joinPaths(this.AUTH_BASE, "doctor/update-note", id),

    DASHBOARD_COUNT: this.joinPaths(this.AUTH_BASE, "doctor/dashboard-count"),
    NEW_REQUEST: type => this.joinPaths(this.AUTH_BASE, "doctor/upcoming-new-appointment", type),

    ADD_SCHEDULE: this.joinPaths(this.AUTH_BASE, "doctor/add-schedule"),
    DOCTOR_HOSPITAL_LIST: this.joinPaths(this.AUTH_BASE, "doctor/hospital-list"),

    GET_SCHEDULE: (hospitalId, doctorId, date) => this.joinPaths(this.AUTH_BASE, "doctor/get-schedule",
      hospitalId, doctorId + "?date=" + date),
    DELETE_SCHEDULE: id => this.joinPaths(this.AUTH_BASE, "doctor/delete-schedule", id),
    GET_SCHEDULE_DETAIL: id => this.joinPaths(this.AUTH_BASE, "doctor/schedule", id),
    UPDATE_SCHEDULE: id => this.joinPaths(this.AUTH_BASE, "doctor/update-schedule", id),
    GET_SCHEDULE_BY_DATE: (date, doctorId, hospitalId) => this.joinPaths(this.AUTH_BASE, "doctor/date-wise-schedule?date="
      + date + "&hospital_id=" + hospitalId + "&doctor_id=" + doctorId),

      DOCTOR_HOSPITAL_INSURANCE: this.joinPaths(this.AUTH_BASE, "doctor/doctor-hospital-insurance"),



  };

  HOSPITAL_ENDPOINT = {
    GET_DASHBOARD: this.joinPaths(this.AUTH_BASE, "hospital/dashboard"),
    DASHBOARD_CHART: type => this.joinPaths(this.AUTH_BASE, "hospital/dashboard-chart?type=" + type),

    ADD_DOCTOR: this.joinPaths(this.AUTH_BASE, "hospital/add-doctor"),
    DOCTOR_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "hospital/doctors?page=" + page + "&name=" + queryText),
    SEARCH_DOCTOR_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "hospital/search-doctor?page=" + page + "&first_name=" + queryText),
    SEND_REQUEST_DOCTOR: id => this.joinPaths(this.AUTH_BASE, "hospital/send-request", id),
    UPDATE_DOCTOR_STATUS: this.joinPaths(this.AUTH_BASE, "hospital/update-doctor-status"),
    UPDATE_REQUEST_STATUS: this.joinPaths(this.AUTH_BASE, "hospital/update-request-status"),
    GET_DOCTOR_PROFILE: id => this.joinPaths(this.AUTH_BASE, "hospital/get-doctor", id),
    GET_DOCTOR_COMPLETED_DETAILS: id => this.joinPaths(this.AUTH_BASE, "hospital/get-doctor-all-detail", id),
    UPDATE_DOCTOR_PROFILE: (doctorId, hospitalId) => this.joinPaths(this.AUTH_BASE, "hospital/update-doctor-detail", doctorId, hospitalId),

    GET_HOSPITAL_PROFILE: (languageCode, hospitalId) => this.joinPaths(this.AUTH_BASE, "hospital/profile", hospitalId +
      "?language_code=" + languageCode),
    UPDATE_HOSPITAL_PROFILE: this.joinPaths(this.AUTH_BASE, "hospital/update-profile"),
    UPLOAD_HOSPITAL_PROFILE_PICTURE: this.joinPaths(this.AUTH_BASE, "hospital/upload-profile-photo"),
    REMOVE_PROFILE_IMAGE: this.joinPaths(this.AUTH_BASE, "hospital/remove-profile-photo"),
    UPDATE_SETTING: this.joinPaths(this.AUTH_BASE, "hospital/update-settings"),

    APPOINEMENT_LIST: (page, queryText, type) =>
      this.joinPaths(this.AUTH_BASE, "hospital/appointments?type=" + type + "&page=" + page + "&patient_name=" + queryText),
    CANCEL_APPOINTMENT: id => this.joinPaths(this.AUTH_BASE, "hospital/cancel-appointment", id),

    SWAPPED_APPOINEMENT_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "hospital/swapped-appointments?page=" + page + "&name=" + queryText),
    SUBSCRIPTION_PLAN_LIST: (page, queryText) =>
      this.joinPaths(this.AUTH_BASE, "hospital/subscription-plans?page=" + page + "&patient_name=" + queryText),
    GET_EXPORT_CSV: type => this.joinPaths(this.AUTH_BASE, "hospital/download-file?type=" + type),




  };
  private joinPaths(...params) {
    const newUrl = params.join("/");
    return newUrl;
  }

  private joinPathsWithQueryParams(...params) {
    const newUrl = params.join("?");
    return newUrl;
  }
}
export const API = new Endpoints();
