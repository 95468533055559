import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-star-rating",
  templateUrl: "./star-rating.component.html",
  styles: [
    `.star {
      position: relative;
      display: inline-block;
      font-size: 1.5rem;
      color: #d3d3d3;
    }
    .full {
      color: #edb867;
    }
    .half {
      position: absolute;
      display: inline-block;
      overflow: hidden;
      color: #edb867;
    }
  `
  ]
})
export class StarRatingComponent implements OnInit {
  @Input() rating: any;
  @Input() ExternalClass: any;
  constructor() { }

  ngOnInit() {
    this.ExternalClass="star-rating"
  }
}
