import { LoaderComponent } from "./../component/loader/loader.component";
import { PaginationComponent } from "./../component/pagination/pagination.component";
import { NgModule, Injectable } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

import { RouterModule } from "@angular/router";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { GlobalDirectivesModule } from "../directives/shared-directives.module";
import { TimeAgoPipe } from "time-ago-pipe";
import { UtilityFunctionService } from "../services/utility-function.service";
import { DataLoaderComponent } from "../component/loader/data.loader.component";
import { MyDatePipe, MyDateTimePipe } from "../services/date-formatter.service";
import { NoDataComponent } from "../component/no-data/no-data.component";
import { StarRatingComponent } from "../component/star-rating/star-rating.component";
import { NgSelectModule } from "@ng-select/ng-select";
import {PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
import { startsWithPipe } from '../pipes/search.pipes';
import {   RestrictSpaceDirective } from '../directives/restrictSpace.directive';
import { PhonePipe } from '../pipes/phone.pipes';
import { DateConversionPipe } from '../pipes/date-conversion.pipes';
import localeAr from '@angular/common/locales/ar'; // for calender arabic and english
import localeEn from '@angular/common/locales/en'; // for calender arabic and english
import { registerLocaleData } from '@angular/common'; // for calender arabic and english
import { CommonLangService } from '@app/language/common/common-lang.service';
registerLocaleData(localeAr); // for calender arabic and english
registerLocaleData(localeEn); // for calender arabic and english


@Injectable()
export class NgbDatepickerI18nPersian extends NgbDatepickerI18n { // for calender multi language
  lan: any;

  constructor(public langService: CommonLangService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    this.lan = this.langService.labelGetter("I18N_VALUES");
    return this.lan.weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    this.lan = this.langService.labelGetter("I18N_VALUES");
    return this.lan.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}-${this.getMonthFullName(date.month)}-${date.day}`;
  }
}


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    InfiniteScrollModule,
    MalihuScrollbarModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    GlobalDirectivesModule,
    NgSelectModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
  ],
  declarations: [
    PaginationComponent,
    LoaderComponent,
    StarRatingComponent,
    NoDataComponent,
    MyDatePipe,
    PhonePipe,
    MyDateTimePipe,
    startsWithPipe,
    //  BarChartComponent,
    DataLoaderComponent,

    // InputFocusDirective,
    TimeAgoPipe,
    RestrictSpaceDirective,
    DateConversionPipe
  ],
  exports: [
    NgSelectModule,
    LoaderComponent,
    StarRatingComponent,
    PaginationComponent,
    NoDataComponent,
    MyDatePipe,
    PhonePipe,
    MyDateTimePipe,
    // BarChartComponent,
    DataLoaderComponent,

    InfiniteScrollModule,
    // InputFocusDirective,
    NgbModule,
    //  ,  MalihuCustomScrollerModule

    MalihuScrollbarModule,
    GlobalDirectivesModule,
    TimeAgoPipe,
    PerfectScrollbarModule,
    NgxPaginationModule,
    RestrictSpaceDirective,
    DateConversionPipe
  ],
  providers: [UtilityFunctionService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nPersian}
  ]
})
export class SharedModule {}
