import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "./moment.js";
import { CommonLangService } from "./language/common/common-lang.service.js";
import { DrawerService } from './shared/services/drawer.service.js';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  lan: any;

  constructor(public langService: CommonLangService, public drawer: DrawerService) {
    super();
  }

  readonly DT_FORMAT = "MMM DD, YYYY";

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.DT_FORMAT);
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) {
      return "";
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return "";
    }
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
      const language = data.name;
      if(language=="AR"){
        return `${this.getMonthFullName(date.month)} ${date.day}، ${date.year}`;
      } else{
        return `${this.getMonthFullName(date.month)} ${date.day}, ${date.year}`;
      }
    }
    return `${this.getMonthFullName(date.month)} ${date.day}, ${date.year}`;
    // return mdt.format(this.DT_FORMAT);
  }

  getMonthShortName(month: number): string {
    this.lan = this.langService.labelGetter("I18N_VALUES");
    return this.lan.months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}
