import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toastConfig = { timeOut: 4000, closeButton: false, enableHtml: true, progressBar: false,
    maxOpened: 1, autoDismiss: true, preventDuplicates: true,  positionClass: 'toast-bottom-center', };

  constructor(private toastrService: ToastrService) {}

  Success(message = "", title?: string) {
    this.RemoveMultipleToaster(this.toastrService.toasts);
    if (message) {
      this.toastrService.success(message, title, this.toastConfig);
    }
  }

  Error(message = "", title?: string) {
    this.RemoveMultipleToaster(this.toastrService.toasts);
    if (message) {
      this.toastrService.error(message, title, this.toastConfig);
    }
  }

  ErrorTimeOut(errMessag: string) {
    this.RemoveMultipleToaster(this.toastrService.toasts);
    if (errMessag) {
      this.toastrService.error(errMessag ? errMessag : "Connection timeout, Please try again later.", "", this.toastConfig);
    }
  }

  RemoveMultipleToaster(toasts: any = []) {
    if (toasts.length > 0) {
      toasts.forEach((element: any) => {
        if (element["toastId"]) {
          this.toastrService.remove(element["toastId"]);
        }
      });
    }
  }
}
