export const error_msg = {

  // add schedule form validations
  START_DATE_REQUIRED: {EN:"Start date is required", AR:"تاريخ البدء الزامي"},
  END_DATE_REQUIRED:  {EN:"End date is required",AR:"تاريخ الانتهاء الزامي"},
  HOSPITAL_REQUIRED: {EN:"Hospital name is required",AR:"اسم المستشفى الزامي"},
  START_TIME_REQUIRED: {EN:"Start time is required",AR:"وقت البدء الزامي"},
  END_TIME_REQUIRED: {EN:"End time is required",AR:"وقت الانتهاء الزامي"},
  SLOT_DURATION_REQUIRED:{EN:"Slot duration is required",AR:"مدة الموعد الزامية"},
  SELECT_DAY_REQUIRED: {EN:"Please select days",AR:"الرجاء تحديد عدد الأيام"},

  FIRST_NAME_REQUIRED: {EN:"First name is required",AR:"الاسم الأول الزامي"},
  LAST_NAME_REQUIRED: {EN:"Last name is required",AR:"اسم العائلة الزامي"},
  SELECT_LOCATION_REQUIRED: {EN:"Location is required",AR:"الموقع الزامي"},
  SELECT_COUNTRY_REQUIRED: {EN:"Country is required",AR:"الدولة الزامية"},
  EMAIL_REQUIRED:{EN:"Please enter email address",AR:" الرجاء ادخال عنوان البريد الالكتروني"},
  PASSWORD_REQUIRED:{EN:"Password is required",AR:"كلمة السر الزامية"},
  CONFIRM_PASSWORD_REQUIRED:{EN:"Please confirm password",AR:"الرجاء تأكيد كلمة السر"},
  NEW_PASSWORD_REQUIRED:{EN:"New password is required",AR:"كلمة السر الجديدة الزامية"},
  CONFIRM_NEW_PASSWORD_REQUIRED:{EN:"Please confirm password",AR:"الرجاء تأكيد كلمة السر"},
  CURRENT_PASSWORD:{EN:"Please enter current password",AR:"كلمة السر الحالية "},
  REQUIRED_TIME:{EN:"Time is required",AR:"الوقت الزامي"},
  NEW_PASSWORD:{EN:"New password is required",AR:"كلمة السر الجديدة الزامية"},
  CONFIRM_NEW_PASSWORD:{EN:"Please confirm password",AR:"تأكيد كلمة المرور مطلوب"},
  EXPERIENCE_REQUIRED:{EN:"Experience is required",AR:"سنوات الخبرة الزامية"},
  DEGREE_REQUIRED:{EN:"Degree is required",AR:"الشهادة الزامية"},
  UNIVERSITY_REQUIRED:{EN:"University is required",AR:" الجامعة الزامية"},
  PLAN_NAME_REQUIRED:{EN:"Plan Name is required",AR:"اسم الباقة الزامي"},
  PRICE_REQUIRED:{EN:"Price is required",AR:"السعر الزامي"},
  NO_OF_DOCTOR_PERMITTED:{EN:"Number of doctors permitted is required",AR:"عدد الأطباء المسموح الزامي"},

  PATIENT_NAME_REQUIRED: {EN:"Patient name is required",AR:"اسم المريض الزامي"},
  DOCTOR_NAME_REQUIRED: {EN:"Doctor name is required",AR:"اسم الطبيب الزامي"},
  CANCEL_REASON_REQUIRED:{EN: "Cancel reason is required",AR:"إلغاء السبب مطلوب"},

  SPECIALTY_NAME_REQUIRED:{EN:"Specialty name is required",AR:"اسم التخصص الزامي"},
  SELECT_SUBSCRIPTION_PLAN_REQUIRED:{EN:"Please select subscription plan",AR:"الرجاء اختيار الباقة"},
  PLAN_TYPE_REQUIRED:{EN:"Plan type is required",AR:"نوع الباقة الزامي"},
  CONTACT_PERSON_NAME_REQUIRED: {EN:"Contact person name is required",AR:"اسم شخص التواصل الزامي"},
  HOSPITAL_NAME_REQUIRED:{EN:"Hospital name is required",AR:"اسم المستشفى الزامي"},

  INSURANCE_NAME_REQUIRED:{EN:"Insurance name is required",AR:"اسم شركة التأمين الزامي"},
  SELECT_STATUS_REQUIRED:{EN:"Status is required",AR:"الرجاء اختيار الحالة"},
  PHONE_NUMBER_REQUIRED: {EN:"Phone number is required",AR:"رقم الهاتف الزامي"},

  WEBSITE_LINK_REQUIRED: {EN:"Website link is required",AR:"رابط الموقع الزامي"},
  CONTACT_ADDRESS_REQUIRED: {EN:"Contact address is required",AR:"عنوان الاتصال الزامي"},

  CITY_REQUIRED: {EN:"City name is required",AR:"اسم المدينة الزامي"},
  CERTIFICATES_REQUIRED: {EN:"Please enter certificate",AR:"الرجاء ادخال الشهادة"},


  // PASSWORD_MAX_LENGTH: maxLength => `Password can't exceed ${maxLength} characters`,
  // PASSWORD_MIN_LENGTH: minLength => `Password should have minimum ${minLength} characters`,

  PASSWORD_MAX_LENGTH: maxLength => `Password must contain 8 to 12 alphanumeric characters`,
  PASSWORD_MIN_LENGTH: minLength =>`Password must contain 8 to 12 alphanumeric characters`,
  FIELD_REQUIRED: {EN:"This field is required", AR: "هذه الخانة مطلوبه"},
  SMARTCARD_NUMBER: {EN:"Smartcard number is required", AR: "الرقم الشخصي الزامي "},
  TERMS_OF_SERVICES: {EN:"Please accept terms of service to proceed", AR: "الرجاء الموافقة على شروط الاستخدام"},
  NOTE_REQUIRED: {EN:"Please enter note", AR: "الرجاء ادخال الملاحظة"},
  COUNTRY_CODE_REQUIRED: {EN:"Country code is required", AR: "رمز الدولة الزامي"},
  CONSULTATION_FEE_REQUIRED: {EN:"Consultation fee is required", AR: "رسوم الاستشارة الزامية "},


};


export const notification_msg = {
  FILL_ALL_DETAILS: "",
  SERVER_NOT_RESPONDING: "Server not responding",
  CODE_NOT_FOUND: "Verification Code Not Found",
};

export const status_msg = {
ALREADY_ASSOCIATED: "Already Associated",
REQUEST_PENDING: "Awaiting Hospital",
TOOLTIP_REQUEST_PENDING: "Doctor haven't accept request yet",
TOOLTIP_REQUEST_PENDING_HOSPITAL: "Hospital haven't accept request yet",
APPROVAL_PENDING: "Awaiting Doctor",
TOOLTIP_APPROVAL_PENDING: "Please accept the doctor request",
TOOLTIP_APPROVAL_PENDING_HOSPITAL: "Please accept the hospital request",
};
export const manage_appointment_status = {
  ACCEPT_STATUS: "Accept",
  REJECT_STATUS: "Reject",

};
export const manage_hospital_status = {
  UNSUSPEND: "active",
  SUSPEND: "suspended",
  DELETED: "deleted",

};
export const manage_doctor_hospital_request_status = {
  JOIN_STATUS: "join",
  REJECT_STATUS: "reject",
  DELETE_STATUS: "delete",
  CANCEL_STATUS: "cancel",
  RESIGN_STATUS: "resign",
};

export const manage_hospital_doctor_request_status = {
  ACCEPT_STATUS: "Accepted",
  REJECT_STATUS: "Rejected",
  CANCEL_STATUS: "Cancelled",
  DELETE_STATUS: "Deleted",

};
export const language_code = {
EN_CODE: "en",
AR_CODE: "ar"
};
export const admin_error_msg = {

  // add schedule form validations
  FIELD_REQUIRED: "This field is required",
  START_DATE_REQUIRED: "Start date is required",
  END_DATE_REQUIRED: "End date is required",
  HOSPITAL_REQUIRED: "Hospital name is required",
  START_TIME_REQUIRED: "Start time is required",
  END_TIME_REQUIRED: "End time is required",
  SLOT_DURATION_REQUIRED: "Slot duration is required",
  SELECT_DAY_REQUIRED: "Day is required",

  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  SELECT_LOCATION_REQUIRED: "Location is required",
  EMAIL_REQUIRED:"Email ID is required",
  PASSWORD_REQUIRED:"Password is required",
  CONFIRM_PASSWORD_REQUIRED:"Please confirm password",

  CURRENT_PASSWORD:"Please enter current password",
  COUNTRY_CODE:"Country code is required",
  COUNTRY_REQUIRED:"Country is required",
  NEW_PASSWORD:"Please enter password",
  CONFIRM_NEW_PASSWORD:"Please confirm password",
  EXPERIENCE_REQUIRED:"Experience is required",
  DEGREE_REQUIRED:"Degree is required",
  UNIVERSITY_REQUIRED:"University is required",
  PLAN_NAME_REQUIRED:"Plan name is required",
  PRICE_REQUIRED:"Price is required",
  MONTH_REQUIRED:"Duration is required",
  NO_OF_DOCTOR_PERMITTED:"Number of doctors permitted is required",
  NO_OF_APPOINTMENT_PERMITTED:"Number of appointment is required",


  PATIENT_NAME_REQUIRED: "Patient name is required",
  DOCTOR_NAME_REQUIRED: "Doctor name is required",

  SPECIALTY_NAME_REQUIRED:"Specialty name is required",
  SELECT_SUBSCRIPTION_PLAN_REQUIRED:"Please select subscription plan",
  PLAN_TYPE_REQUIRED:"Plan type is required",
  CONTACT_PERSON_NAME_REQUIRED: "Contact person name is required",
  HOSPITAL_NAME_REQUIRED:"Hospital name is required",

  QUESTION_EN_REQUIRED: "Question in English is required",
  QUESTION_AR_REQUIRED: "Question in Arabic is required",


  ANSWER_EN_REQUIRED: "Answer in English is required",
  ANSWER_AR_REQUIRED: "Answer in Arabic is required",


  INSURANCE_NAME_REQUIRED:"Insurance name is required",
  SELECT_STATUS_REQUIRED:"Status is required",
  PHONE_NUMBER_REQUIRED: "Phone number is required",

  WEBSITE_LINK_REQUIRED: "Website link is required",
  CONTACT_ADDRESS_REQUIRED: "Contact address is required",

  CITY_REQUIRED: "City name is required",
  TITLE_REQUIRED: "Title is Required",
  CONTENT_REQUIRED: "Content is Required",

  // `Password must contain ${minLength} to ${maxLength} alphanumeric characters`
  // PASSWORD_MAX_LENGTH: maxLength => `Password can't exceed ${maxLength} characters`,
  // PASSWORD_MIN_LENGTH: minLength => `Password should have minimum ${minLength} characters`,


  PASSWORD_MAX_LENGTH: maxLength => `Password must contain 8 to 12 alphanumeric characters`,
  PASSWORD_MIN_LENGTH: minLength =>`Password must contain 8 to 12 alphanumeric characters`,


 /**common error mesage */
 ONLY_ALPHA: "Please enter only alphabetic characters",
 ONLY_NUMERIC: "Please enter only numbers",
 MIN_LENGTH: minLength => `This field should have at least ${minLength} characters`,
 MAX_LENGTH: maxLength => `This field should not exceed ${maxLength} characters`,
 MIN_VALUE: minLength => `This field should have at least ${minLength} characters`,
 MIN_VALUE_REQUIRED: "Password must contain 8 to 12 alphanumeric characters",
 MIN_VALUE_PHONE_REQUIRED: "Phone number must contain 8 to 12 digits",
 MAX_VALUE: maxLength => `This field should not exceed ${maxLength}`,
 MAX_VALUE_DIGIT: maxLength => `This field should not exceed ${maxLength} digit`,
 MAX_VALUE_DIGIT_PERMITTED: maxLength => `Please enter a number between 1 and  ${maxLength}`,
 MAX_VALUE_PRICE: maxLength => `Price should not exceed ${maxLength}`,
 MIN_VALUE_PRICE: minLength => `Price must be at least ${minLength}`,

 PRICE_VALIDATION: "Enter Valid Price",
 DOCTOR_PERMITTEd: "Atleast one doctor should permitted",
 CELL_NUMBER_REQUIRED: "Please enter a valid phone number",
 Validation_Email_Valid: "Please enter a valid email address"



};

export const default_const = {
  //Default_Language: { key: "AR", value: "Arabic" },
  Default_Language: { key: "EN", value: "English" },
  LanguageList: [{ key: "AR", value: "Arabic" }, { key: "EN", value: "English" }],

   // Key for Local Storage
   selectedLanguage: "selectedLanguage",
   selectedLanguageData: "selectedLanguageData",
   currentUser: "currentUser",
   loginInfo: "loginInfo",

}
